import images from '../../../common/images'

export const leaderships = [
  {
    company: 'IVA株式会社',
    profile: images.ceoProfile,
    title: '代表取締役CEO',
    name: '相原 嘉夫',
    position: '代表取締役 執行役員CEO',
    intro:
      '1995年生まれ。青山学院大学大学院国際マネジメント研究科を卒業し、MBAを取得。学生時代に自身一社目となるアパレル関連の事業を展開。そこで成長を続けるスニーカー市場と、市場の長年抱える「フェイク問題」というペインに目をつけ、2019年にAIと鑑定士を掛け合わせることで高い精度を実現した真贋鑑定サービス「FAKEBUSTERS」を立ち上げ、IVA株式会社を設立。日本語・中国語のバイリンガル。「Forbes JAPAN 30 Under 30 2023」、「Forbes 30 Under 30 Asia 2024」選出。'
  },
  {
    company: 'IVA株式会社',
    profile: images.cooProfile,
    title: '取締役 執行役員COO 兼 中国法人代表',
    name: 'ジェイ・ルー',
    position: '取締役 執行役員COO 兼 中国法人代表',
    intro:
      '1995年生まれ。広東外語外貿大学(GUFS)で、中国市場、経営管理を研究後、来日。2019年 IVA株式会社を相原と共に創業し、COOとして事業戦略、組織管理、中国開発拠点の立ち上げを牽引するなど幅広い領域で会社のグロースをサポート。中国語・日本語のバイリンガル。'
  },
  {
    company: '社外取締役\nDe Capital 代表取締役',
    profile: images.oooProfile,
    title: '社外取締役',
    name: '吉田 知洋',
    position: '',
    intro:
        '1987年生まれ。アジア最大級の財閥であるCP Groupと日本政策投資銀行の共同投資ファンド（En Fund）、及び国内最大級のファンド運用会社である株式会社マーキュリアインベストメントを経て、De Capitalを創業。PE/VC/HF/Crypto等、多岐にわたる資産クラスにおいて卓越した投資実績を誇る。'
  }
]
